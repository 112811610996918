import React, { useEffect } from "react"
import { CONSTANTS, DEVICE, PLATFORM, THEME } from "./_shared/constants/constants"

import { useDeviceStore } from "./stateManager/DeviceStore";
import { useOsStore } from "./stateManager/OsStore";
import { useThemeStore } from "./stateManager/ThemeStore";


const useAppHook = () => {

    const { device, setDevice } = useDeviceStore();
    const { os, setOs } = useOsStore();
    const { theme, setTheme } = useThemeStore();

    function handleResize() {
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }

        getOS();
        setTheme(THEME.LIGHT)
    }, [])


    const getOS = (): any => {
        
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;

        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const androidPlatforms = ['Android'];

        // setOs(window.navigator.userAgent)
        if (windowsPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.WIN)
            return 'Windows';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width > 1400) {
            setOs(PLATFORM.MAC)
            return 'mac';
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.IOS)
            return 'iOS';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width < 1200) {
            setOs(PLATFORM.IOS)
            return 'iPadOS';
        }
        else if (userAgent.indexOf('Android') !== -1) {
            setOs(PLATFORM.AND)
            return 'android'
        }
        else if (/Linux/.test(userAgent)) {
            setOs("linux")
            return 'Linux';
        }

        setOs(PLATFORM.WIN)
        return 'Unknown OS';
    };

    return {

    }
}
export default useAppHook
import { downloadService } from "../../../app.registration";
import { PLATFORM } from "../../constants/constants";
import { useOsStore } from "../../../stateManager/OsStore";
import { useDownloadHook } from "../../hooks/download.hook";

const useNavbarHook = () => {

    const { os, setOs } = useOsStore();
    const { handleBuildDownload } = useDownloadHook()



    return {
        os,
        handleBuildDownload,
    }
}

export default useNavbarHook